import {
  VideoTitle,
  LiveStreamReplayVideoYoutubeLogo,
  SSIFutureFiresideVideoWrapper,
  VideoTitleLogo,
  YoutubeButton,
} from './style';
import React from 'react';
import atalaLogo from './atala-prism-logo-2024-orange.png';
import youtubeLogo from './youtube-icon.png';

export function SSIFutureFiresideVideo() {
  return (
    <SSIFutureFiresideVideoWrapper>
      <VideoTitleLogo src={atalaLogo} />
      <VideoTitle>
        The Future of SSI: <br /> Hyperledger Fireside
      </VideoTitle>
      <YoutubeButton
        onClick={() =>
          window.open(
            'https://youtu.be/LgtzU28VyIc?si=OPXQDFcMhJs_gnzD',
            '_blank',
            'noopener,noreferrer',
          )
        }
      >
        <LiveStreamReplayVideoYoutubeLogo src={youtubeLogo} />
      </YoutubeButton>
    </SSIFutureFiresideVideoWrapper>
  );
}
