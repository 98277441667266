import React from 'react';
import {
  PresentCollectionItemTitle,
  PresentCollectionItemWrapper,
  PresentCollectionItemDescription,
  PresentCollectionItemImageWrapper,
} from './style';
import SlashIcon from './slash-icon';
import { CollectionItemTypes } from './types';

export default function PresentCollectionItem({
  variant,
  image,
  title,
  description,
}: CollectionItemTypes) {
  return (
    <PresentCollectionItemWrapper variant={variant}>
      {image && (
        <PresentCollectionItemImageWrapper>
          <img {...image} loading="lazy" />
        </PresentCollectionItemImageWrapper>
      )}
      <PresentCollectionItemTitle>
        <SlashIcon />
        {title}
      </PresentCollectionItemTitle>
      <PresentCollectionItemDescription dangerouslySetInnerHTML={{ __html: description }} />
    </PresentCollectionItemWrapper>
  );
}
