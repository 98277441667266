import { styled } from '@linaria/react';
import colors from '../../helpers/colors';
import { FONT_32_SIZE, SECTION_SPACING_Y } from '../../helpers/constants';
import { rgba } from 'polished';
import getSize from '../../helpers/get-size';

export const SandboxWrapper = styled.section`
  ${SECTION_SPACING_Y};
  padding-bottom: 0;
  background-color: ${rgba(colors.gray100, 0.4)};
  position: relative;
`;

export const SandboxContentWrapper = styled.div`
  padding: 0;

  h5.pre-title {
    font-size: ${getSize(18)}em;
    color: ${colors.primary};
    font-weight: 600;
    line-height: 1;
    margin-bottom: 22px;
  }

  h1.title {
    ${FONT_32_SIZE};
    color: ${colors.darkblue900};
    font-weight: 600;
    margin-bottom: 22px;
  }
`;

export const SandboxContentBody = styled.div`
  display: flex;
  gap: 2rem;

  .left {
    flex: 1;

    p {
      font-size: ${getSize(18)}em;
      font-weight: 400;
      color: ${colors.darkblue100};
      margin-bottom: 22px;
      margin-top: 0;
    }
  }

  .right {
    display: none;
    flex: 1;
  }

  @media (min-width: 800px) {
    .right {
      display: block;
    }
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 36px 0;

  @media (min-width: 500px) {
    justify-content: flex-start;
  }
`;
