import { styled } from '@linaria/react';
import getSize from '../../helpers/get-size';
import colors from '../../helpers/colors';
import { Container } from '../../helpers/styles';
import { FONT_18_SIZE, FONT_32_SIZE, SECTION_SPACING_Y } from '../../helpers/constants';
import { rgba } from 'polished';

export const ReactOutWrapper = styled.div`
  ${SECTION_SPACING_Y};
  margin: 0;
  overflow: hidden;
  background-color: ${rgba(colors.gray100, 0.4)};

  ${Container} {
    position: relative;
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: translateX(50%) translateY(30%);
  }

  a,
  a:hover {
    color: ${colors.primary} !important;
  }

  #hubspotForm {
    max-width: ${getSize(507)}em;
  }
`;

const CONTENT_VERTICAL_SPACING = getSize(22);

export const ReachOutIntroContent = styled.div`
  padding: 0;
  max-width: ${getSize(778)}rem;
  margin-bottom: ${getSize(52)}em;
  //background-color: ${colors.white};
  z-index: 10;
  .privacy-policy {
    color: ${colors.darkblue100} !important;
  }
  h5 {
    font-size: ${getSize(18)}em;
    color: ${colors.primary};
    font-weight: 600;
    line-height: 1;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }

  h1 {
    ${FONT_32_SIZE};
    color: ${colors.darkblue900};
    font-weight: 600;
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }

  p {
    font-size: ${getSize(18)}em;
    font-weight: 400;
    color: ${colors.darkblue100};
    margin-bottom: ${CONTENT_VERTICAL_SPACING}rem;
  }
`;
