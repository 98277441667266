import React, { ReactNode } from 'react';
import {
  FooterCopyrightWrapper,
  FooterLogoWrapper,
  FooterWrapper,
  FooterLinksWrapper,
  FooterBgWrapper,
} from './style';
import { Container } from '../../helpers/styles';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import BgLogo from './bg_footer_logo.svg';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <Container>
        <FooterBgWrapper>
          <BgLogo />
        </FooterBgWrapper>
        <FooterLogoWrapper>
          <img src="/images/atala-prism-logo-2024.png" alt="Atala Prism logo" />
        </FooterLogoWrapper>
        <FooterLinksWrapper>
          <a href={t('footer.link-terms.url')} target="_blank" rel="noopener">
            {t('footer.link-terms.text') as ReactNode}
          </a>
          <a href={t('footer.link-privacy.url')} target="_blank" rel="noopener">
            {t('footer.link-privacy.text') as ReactNode}
          </a>
        </FooterLinksWrapper>
        <FooterCopyrightWrapper>
          {t('footer.copyright', { year: new Date().getFullYear() }) as ReactNode}
        </FooterCopyrightWrapper>
      </Container>
    </FooterWrapper>
  );
}
