import React from 'react';
import {PageTypes} from "./types";
import {PageWrapper} from "./style";
import SEO from "../seo/seo";
import SectionTrackingProvider from "../section-tracking/provider";
import {globals} from '../../helpers/styles';

export default function Page({children}: PageTypes) {
    return (
        <PageWrapper>
            <SEO/>
            <SectionTrackingProvider>
                {children}
            </SectionTrackingProvider>
        </PageWrapper>
    )
}