import React, {useCallback, useEffect, useState} from "react";
import {ProviderTypes} from "./types";
import {SectionTrackingContext} from "./context";
import {SectionIdType} from "../../helpers/types";

export default function SectionTrackingProvider({children}: ProviderTypes) {
  const [currentId, setCurrentId] = useState<SectionIdType | null>(null);
  const [intersectionRef, setIntersectionRef] = useState<IntersectionObserver | null>(null);
  const [previousY, setPreviousY] = useState(0);

  const callback = useCallback<IntersectionObserverCallback>((e) => {
    const items = e.filter(item => item.isIntersecting);
    const sorted = items.sort((a, b) => a.intersectionRatio > b.intersectionRatio ? 1 : 0);

    sorted.length && setCurrentId(sorted.at(-1)?.target.id as SectionIdType ?? null);
  }, [currentId, previousY, setPreviousY]);

  useEffect(() => {
    setIntersectionRef(new IntersectionObserver(callback, {
      root: document,
      threshold: 0.2,
    }));

  }, []);

  return (
      <SectionTrackingContext.Provider value={{currentId, setCurrentId, observer: intersectionRef}}>
        {children}
      </SectionTrackingContext.Provider>
  );
}