// eslint-disable-next-line camelcase
import { NavbarItem } from './types';
import getSize from './get-size';
// sizes
export const ROOT_FONT_SIZE = 16;
export const CONTAINER_MAX_SIZE = 1048;

const DEFAUlT_PAGE_SIZE = 100;
// eslint-disable-next-line camelcase
export const HOLDER_PAGE_SIZE = DEFAUlT_PAGE_SIZE;
// eslint-disable-next-line camelcase
export const GROUP_PAGE_SIZE = DEFAUlT_PAGE_SIZE;
// eslint-disable-next-line camelcase
export const CREDENTIAL_PAGE_SIZE = DEFAUlT_PAGE_SIZE;
// eslint-disable-next-line camelcase
export const CREDENTIAL_SUMMARY_PAGE_SIZE = DEFAUlT_PAGE_SIZE;
// eslint-disable-next-line camelcase
export const PAYMENT_PAGE_SIZE = 20;
// eslint-disable-next-line camelcase
export const HARDCODED_LIMIT = 1000;

export const xScroll = 1300;
export const yScroll = 600;
export const drawerWidth = 450;
export const AVATAR_WIDTH = 50;

export const RIGHT = 'right';
export const LEFT = 'left';

const JPEG = 'image/jpeg';
const PNG = 'image/png';
export const ALLOWED_TYPES = [JPEG, PNG];
export const MAX_FILE_SIZE = 1073741824; // 1 Gb as maximum just so all my images can pass

// Local storage item names
export const USER_ROLE = 'userRole';
export const ORGANISATION_NAME = 'organisationName';
export const LOGO = 'logo';

// Roles
export const VERIFIER = 'VERIFIER';
export const ISSUER = 'ISSUER';

// Wallet
export const MISSING_WALLET_ERROR = 'Wallet cannot be Unlocked';
const MISSING = 'MISSING';
export const UNLOCKED = 'UNLOCKED';
const LOCKED = 'LOCKED';
const WalletStatuses = {
  0: MISSING,
  1: UNLOCKED,
  2: LOCKED,
};

// @ts-ignore
export const translateStatus = (status) => WalletStatuses[status];

export const CREDENTIAL_TYPES = {
  0: 'GOVERNMENT_ISSUED_DIGITAL_IDENTITY',
  1: 'UNIVERSITY_DEGREE',
  2: 'PROOF_OF_EMPLOYMENT',
  3: 'INSURANCE_POLICY',
};

export const GOVERNMENT_ISSUED_DIGITAL_IDENTITY = 0;
export const UNIVERSITY_DEGREE = 1;
export const PROOF_OF_EMPLOYMENT = 2;
export const INSURANCE_POLICY = 3;

export const SUBJECT_STATUSES = {
  0: 'UNCONNECTED',
  1: 'CONNECTED',
  2: 'CREDENTIAL_AVAILABLE',
  3: 'CREDENTIAL_SENT',
  4: 'CREDENTIAL_RECEIVED',
};

export const CONNECTED = 1;
export const CREDENTIAL_SENT = 3;

export const USER = 'atala-demo-web-user';

export const PAGE = 'page';
export const LANDING_PAGE = 'landing';
export const CREDENTIALS_PAGE = 'credentials';

export const NAVBAR_ITEMS: NavbarItem[] = [
  {
    textKey: 'navbar.menu-items.why',
    isExternal: false,
    opensInNewTab: false,
    link: '#why',
  },
  {
    textKey: 'navbar.menu-items.developers',
    isExternal: false,
    opensInNewTab: false,
    link: '#hyperledger-identus',
  },
  {
    textKey: 'navbar.menu-items.who',
    isExternal: false,
    opensInNewTab: false,
    link: '#fosters-growth',
  },
  // {
  //   textKey: "navbar.menu-items.case-studies",
  //   isExternal: false,
  //   opensInNewTab: false,
  //   link: "#case-studies"
  // },
  // {
  //   textKey: "navbar.menu-items.pioneer",
  //   isExternal: false,
  //   opensInNewTab: false,
  //   link: "#start-journey"
  // },
  // {
  //   textKey: "navbar.menu-items.demo",
  //   isExternal: false,
  //   opensInNewTab: false,
  //   link: "#demo"
  // },
  {
    textKey: 'navbar.menu-items.team',
    isExternal: false,
    opensInNewTab: false,
    link: '#meet-the-team',
  },
  {
    textKey: 'navbar.menu-items.blog',
    isExternal: true,
    opensInNewTab: true,
    link: 'https://atala.mymidnight.blog',
  },
];

export const APP_STORE_URL = 'https://apps.apple.com/app/atala-prism/id1515523675';
export const GOOGLE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=io.iohk.cvp';

// EXAMPLE CERTIFICATE CARD VALUES
export const CARD_UNIVERSITY_TITLE = 'Bachelor of Science';
export const CARD_UNIVERSITY_UNIVERSITY = 'University of Innovation and Technology';
export const CARD_UNIVERSITY_AWARD = 'First-class honors';
export const CARD_EMPLOYMENT_COMPANY = 'Decentralized Inc.';
export const CARD_EMPLOYMENT_ADDRESS = '67 Clasper Way, Herefoot, HF1 0AF';
export const CARD_EMPLOYMENT_STATUS = 'Full-time';
export const CARD_INSURANCE_PROVIDER = 'Verified Insurance Ltd.';
export const CARD_INSURANCE_CLASS = 'Health Insurance';
export const CARD_INSURANCE_POLICY_NUMBER = 'ABC-123456789';

export const GET_CREDENTIALS_EVENT = 'Get_Credentials';
export const SUPPORT_EVENT = 'support';
export const RESET_DEMO_EVENT = 'reset_demo';
export const CONTACT_US_EVENT = 'contact_us';
export const STEP_1_EVENT = 'step_1_event';
export const STEP_2_EVENT = 'step_2_event';
export const STEP_3_EVENT = 'step_3_event';
export const STEP_4_EVENT = 'step_4_event';
export const BLOG_EVENT = 'blog';
export const BLOG_POST_EVENT = 'blog_post_';
export const RESOURCES_EVENT = 'resources_menu';
export const PIONEERS_EVENT = 'pioneers';

// RESPONSIVE CSS | MARGINS | PADDING " FONT-SIZE
export const FONT_18_SIZE = `font-size: clamp(${getSize(18 * 0.9)}em, 2vw, ${getSize(18)}em)`;
export const FONT_32_SIZE = `font-size: clamp(${getSize(32 * 0.9)}em, 2vw, ${getSize(32)}em)`;

const WRAPPER_Y_SPACING = getSize(130);
const RESPONSIVE_RATIO = 0.4;
const RESPONSIVE_MARGIN_RATIO = 0.5;
const RESPONSIVE_PADDING_RATIO = 0.4;

export const SECTION_SPACING_Y = `padding: clamp(${WRAPPER_Y_SPACING * RESPONSIVE_PADDING_RATIO}rem, 10vw, ${WRAPPER_Y_SPACING}rem) 0`;
export const CONTAINER_SPACING_Y = `padding: clamp(${WRAPPER_Y_SPACING * RESPONSIVE_PADDING_RATIO}rem, 10vw, ${WRAPPER_Y_SPACING}rem) 1em`;

export const SECTION_SPACING_BOTTOM = `margin-bottom: clamp(${WRAPPER_Y_SPACING * RESPONSIVE_MARGIN_RATIO}rem, 10vw, ${WRAPPER_Y_SPACING}rem)`;
export const SECTION_PADDING_BOTTOM = `padding-bottom: clamp(${WRAPPER_Y_SPACING * RESPONSIVE_MARGIN_RATIO}rem, 10vw, ${WRAPPER_Y_SPACING}rem)`;
