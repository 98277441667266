import { styled } from '@linaria/react';
import colors from '../../helpers/colors';
import { Container } from '../../helpers/styles';
import getSize from '../../helpers/get-size';
import { BurgerTypes, MenuItemStyleTypes, NavbarMobileTypes } from './types';

const MEDIA_MIN_DESKTOP = 990;
export const NavbarsWrappers = styled.nav`
  position: sticky;
  z-index: 100;
  height: auto;
  top: 0;
  bottom: 0;
  right: 0;
`;
export const NavbarWrapperDesktop = styled.nav`
  position: relative;
  padding: 1em 0;
  height: auto;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.33);
  justify-content: space-between;
  //flex-wrap: wrap;
  display: none;

  ${Container} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  @media (min-width: ${MEDIA_MIN_DESKTOP}px) {
    display: flex;
  }
`;

export const LogoWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

export const MenuWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${getSize(27)}rem;
`;

const BORDER_HEIGHT = 0.2;
export const MenuItemWrapper = styled.li<MenuItemStyleTypes>`
  padding: 0;
  list-style-type: none;

  a {
    font-size: 14px;
    position: relative;
    color: ${colors.black};
    font-weight: 600;
    text-decoration: none;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: ${BORDER_HEIGHT}em;
      transform: translateY(${BORDER_HEIGHT}em);
      width: ${({ sectionVisible }) => (sectionVisible ? '100%' : '0%')};
      background-color: ${({ sectionVisible }) =>
        sectionVisible ? colors.primary : 'transparent'};
      transition: all ease-in-out 0.3s;
    }

    img {
      position: absolute;
      top: 0;
      right: -0.3em;
    }
  }

  a:hover {
    color: ${colors.gray400};
  }
`;

export const ContactWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transform: translateX(-25%);

  a {
    display: inline-block;
    margin-left: 25%;
  }

  @media screen and (min-width: ${MEDIA_MIN_DESKTOP}px) {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    transform: unset;
    align-items: center;
    a {
      margin-left: unset;
    }
  }
`;

export const BurgerWrapper = styled.div<BurgerTypes>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  cursor: pointer;

  span {
    width: 1.5em;
    background-color: ${colors.primary};
    height: 0.3em;
    transition: all ease-in-out 0.3s;
    border-radius: 0.2em;

    &:nth-child(2) {
      // transform: translateX(${({ times }) => (times ? '5em' : '.3em')});
      transform: translateX(${({ times }) => (times ? '5em' : '0')});
      opacity: ${({ times }) => (times ? 0 : 1)};
    }

    &:nth-child(1) {
      transform-origin: center;
      transform: rotate(${({ times }) => (times ? '45deg' : '0')})
        translateY(${({ times }) => (times ? '.72em' : '0')});
    }

    &:nth-child(3) {
      transform-origin: center;
      transform: rotate(${({ times }) => (times ? '-45deg' : '0')})
        translateY(${({ times }) => (times ? '-.72em' : '0')});
    }
  }
`;

// @ts-ignore
export const Drawer = styled.div<NavbarMobileTypes>`
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  ${Container} {
    position: fixed;
    top: 66px;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    padding: 1em;
    display: block !important;
    transform: translateX(${({ open }) => (open ? 25 : 100)}%);
    pointer-events: ${({ open }) => (open ? 'all' : 'none')};
    transition: all ease-in-out 0.3s;
    text-align: center;

    ${ContactWrapper} {
      margin-top: 1em;
    }
  }

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
  }
`;

export const NavbarWrapperMobile = styled.nav`
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  @media (min-width: ${MEDIA_MIN_DESKTOP}px) {
    display: none;
  }

  ${Container} {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding: 1em;
    background-color: rgba(255, 255, 255, 1);
  }

  ${MenuWrapper} {
    flex-direction: column;
    transform: translateX(-12.5%);
  }
`;

export const DiscordIconWrappers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & .discord-path {
    fill: #687a84;
  }

  &:hover .discord-path {
    fill: #1c3747;
  }
`;
