import { styled } from '@linaria/react';
import { Container } from '../../helpers/styles';
import colors from '../../helpers/colors';
import getSize from '../../helpers/get-size';
import { rgba } from 'polished';

const TEXT_FONT_SIZE = getSize(11);
const LINE_HEIGHT = getSize(24);
export const FooterWrapper = styled.footer`
  margin: 0;
  padding: 0;
  width: 100%;
  color: ${colors.darkblue100};
  display: flex;
  background-color: ${rgba(colors.gray100, 0.4)};

  ${Container} {
    display: grid;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (min-width: 750px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export const FooterBgWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: -100px;
  z-index: -3;
`;

export const FooterLogoWrapper = styled.div`
  margin: 0;
  padding: 1em 0;

  img {
    width: 88px;
  }
`;

export const FooterLinksWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: clamp(${getSize(10)}em, 2vw, ${getSize(33)}em);

  a {
    color: inherit;
    font-size: ${TEXT_FONT_SIZE}em;
    line-height: ${LINE_HEIGHT}em;
  }
`;

export const FooterCopyrightWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: ${TEXT_FONT_SIZE}em;
  line-height: ${LINE_HEIGHT}em;
`;
