import {styled} from "@linaria/react";
import {CONTAINER_MAX_SIZE} from "./constants";
import getSize from "./get-size";
import {css} from '@linaria/core';

export const Container = styled.div`
  max-width: ${getSize(CONTAINER_MAX_SIZE + 16)}em;
  margin: 0 auto;
  padding: 0 1em;
  position: relative;
`

export const globals =  css`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700&display=swap');
`
