import { ColorsType } from './types';

const colors = {
  primary: '#007FDC',
  secondary_new: '#42AFFF',
  primary100: '#D4EAFA',
  red100: '#FCDDDC',
  white: '#ffffff',
  black: '#000000',
  gray100: '#E6E9EB',
  gray200: '#F7F7F7',
  gray300: '#E0E0E0',
  gray400: 'rgb(163, 171, 176)',
  darkblue100: '#687A84',
  darkblue900: '#1C3747',
  blue200: '#A4DDEB',
  darkGreen: 'rgb(0, 107, 94)',
  green900: '#00E6B3',
  green300: '#C3F9E0',
  green100: '#D7F0F6',
  hyperledger: 'rgb(97, 0, 255)',
  lightBlue100: 'rgb(112, 229, 255)',
  lightBlue500: 'rgb(82, 132, 197)',
  lightBlue900: 'rgb(45, 71, 102)',
} as ColorsType;

export default colors;
