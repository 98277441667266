import React from 'react';
import { DiscordIconWrappers } from './style';

export function DiscordIcon() {
  return (
    <DiscordIconWrappers>
      <svg
        width="26"
        height="20"
        viewBox="0 0 26 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1539_13255)">
          <path
            className="discord-path"
            d="M22.0245 1.67497C20.3163 0.881212 18.5129 0.318094 16.6605 0C16.407 0.459917 16.1777 0.933111 15.9734 1.4176C14.0002 1.11582 11.9936 1.11582 10.0204 1.4176C9.81605 0.933161 9.58669 0.459973 9.33331 0C7.47971 0.32078 5.67516 0.885235 3.96522 1.67912C0.570532 6.77667 -0.349713 11.7476 0.11041 16.648C2.09842 18.1388 4.32357 19.2725 6.68914 20C7.2218 19.2729 7.69313 18.5015 8.09814 17.6941C7.32888 17.4025 6.58641 17.0427 5.87933 16.6189C6.06542 16.4819 6.24742 16.3408 6.42329 16.2038C8.48075 17.1859 10.7264 17.695 13 17.695C15.2736 17.695 17.5192 17.1859 19.5767 16.2038C19.7546 16.3512 19.9366 16.4923 20.1206 16.6189C19.4122 17.0434 18.6684 17.4038 17.8977 17.6961C18.3022 18.5032 18.7736 19.2739 19.3067 20C21.6743 19.2755 23.9012 18.1422 25.8895 16.6501C26.4294 10.9672 24.9673 6.04193 22.0245 1.67497ZM8.68096 13.6343C7.39875 13.6343 6.33945 12.4533 6.33945 11.0004C6.33945 9.54753 7.36194 8.35616 8.67687 8.35616C9.9918 8.35616 11.0429 9.54753 11.0204 11.0004C10.9979 12.4533 9.98771 13.6343 8.68096 13.6343ZM17.319 13.6343C16.0347 13.6343 14.9795 12.4533 14.9795 11.0004C14.9795 9.54753 16.002 8.35616 17.319 8.35616C18.636 8.35616 19.6789 9.54753 19.6564 11.0004C19.6339 12.4533 18.6257 13.6343 17.319 13.6343Z"
            fill=""
          />
        </g>
        <defs>
          <clipPath id="clip0_1539_13255">
            <rect width="26" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </DiscordIconWrappers>
  );
}
