import React from 'react';
import { Background, InfoCardWrapper } from './style';

export function InfoCard({ children }: { children: React.ReactNode }) {
  return (
    <InfoCardWrapper>
      {/*<Background src="/images/info-card-background-alt.svg" alt="Developer driven" />*/}
      {children}
    </InfoCardWrapper>
  );
}
