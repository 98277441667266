import React from 'react';
import useEmblaCarousel, { UseEmblaCarouselType } from 'embla-carousel-react';
import { Container, Slide, Wrapper } from './styles';
import { Controls } from './controls';

export function Carousel({ slides }: { slides: React.ReactNode[] }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });

  return (
    <Wrapper ref={emblaRef}>
      <Container>
        {slides.map((content) => (
          <Slide>{content}</Slide>
        ))}
      </Container>
      <Controls api={emblaApi} />
    </Wrapper>
  );
}
