import React from "react";
import {ButtonWrapper} from "./style";
import {ButtonTypes} from "./types";

export default function Button({as, children, href, target, variant, to }:ButtonTypes) {
    return (
        <ButtonWrapper {...{variant, to, target, href, as}}>
            {children}
        </ButtonWrapper>
    )
}