import { styled } from '@linaria/react';
import { Container } from '../../helpers/styles';
import getSize from '../../helpers/get-size';

const MEDIA_BREAK_AT = getSize(700);
const MEDIA_BREAK_AT_SMALL = getSize(400);

export const HyperledgerIdentusWrapper = styled.section`
  margin: 0;
  padding: 0 10%;
  height: 678px;
  background: linear-gradient(to bottom, rgb(102, 150, 255) 0%, rgb(70, 119, 226) 100%);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Background1 = styled.img`
  position: absolute;
  left: -150px;
  top: -300px;
  z-index: 0;
  pointer-events: none;
`;

export const Background2 = styled.img`
  position: absolute;
  right: -250px;
  bottom: -200px;
  z-index: 0;
  pointer-events: none;
`;

export const InfoCardWrapper = styled.section`
  margin: 0;
  padding: 0 1rem;
  background: linear-gradient(
    to bottom,
    rgba(229, 238, 255, 0.9) 0%,
    rgba(204, 220, 255, 0.9) 50%,
    rgba(154, 186, 255, 0.8) 100%
  );
  overflow: hidden;

  width: 100%;
  height: 520px;

  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    width: 460px;
    margin-top: 2rem;
  }

  & h1,
  & p {
    color: rgba(0, 87, 167, 1);
    text-align: center;
  }

  & h1 {
    margin: 0;
    margin-top: 1rem;
    font-weight: 600;
    font-size: 32px;
  }

  & p {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 20px;
  }

  @media (max-width: ${MEDIA_BREAK_AT}em) {
    & h1 {
      margin-top: 2rem;
    }
    & .hidden-on-sm {
      display: none;
    }

    .logo {
      width: 300px;
    }

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 18px;
    }
  }

  @media (max-width: ${MEDIA_BREAK_AT_SMALL}em) {
    & .hidden-on-xs {
      display: none;
    }

    .logo {
      width: 200px;
    }
  }
`;
