import React, { ReactNode } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  HeroWrapper,
  HeroContentWrapper,
  HeroImageOverflow,
  HeroMainImageWrapper,
  HeroBackgroundImage,
} from './style';
import { Container } from '../../helpers/styles';

export default function Hero() {
  const { t } = useTranslation();
  return (
    <>
      <HeroWrapper id="hero">
        <HeroBackgroundImage>
          <img src="/images/hero-background.svg" alt="Image hero" />
        </HeroBackgroundImage>
        <HeroMainImageWrapper>
          <img src="/images/hero-image-clipped.png" alt="Image hero" />
        </HeroMainImageWrapper>
        <HeroImageOverflow>
          <Container>
            <HeroContentWrapper>
              <h1>{t('landing.hero.title') as ReactNode}</h1>
              <p>{t('landing.hero.description') as ReactNode}</p>
            </HeroContentWrapper>
          </Container>
        </HeroImageOverflow>
      </HeroWrapper>
    </>
  );
}
