import { styled } from '@linaria/react';
import colors from '../../helpers/colors';
import getSize from '../../helpers/get-size';

const MEDIA_BREAK_AT = getSize(700);
const MEDIA_BREAK_AT_SMALL = getSize(430);

export const InfoCardPositionInTheMiddle = styled.div`
  position: absolute;
  left: 10%;
  right: 10%;
  top: -170px;
`;

export const InfoCardWrapper = styled.section`
  margin: 0;
  padding: 0 1rem;
  background: url('/images/info-card-background-alt.svg'),
    linear-gradient(to bottom, rgb(31, 110, 182) 0%, rgb(10, 57, 105) 100%);
  overflow: hidden;
  background-repeat: repeat;
  background-position: -20px 0;

  width: 100%;
  height: 349px;

  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h1,
  & p {
    color: white;
    text-align: center;
  }

  & h1 {
    margin: 0;
    font-weight: 600;
    font-size: 32px;
  }

  & p {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 18px;
  }

  @media (max-width: ${MEDIA_BREAK_AT}em) {
    & .hidden-on-sm {
      display: none;
    }
  }

  @media (max-width: ${MEDIA_BREAK_AT_SMALL}em) {
    & .hidden-on-xs {
      display: none;
    }
  }
`;

export const Background = styled.img`
  position: absolute;
  z-index: 0;
  pointer-events: none;
`;
