import {useContext, useEffect, useRef} from "react";
import {SectionTrackingContext} from "./context";
import {SectionIdType} from "../../helpers/types";

export default function useSectionTracking(id: SectionIdType) {
  const ref = useRef<HTMLDivElement>(null);
  const {currentId, observer} = useContext(SectionTrackingContext);
  
  useEffect(() => {
    ref.current && observer?.observe(ref.current);
    return () => observer?.disconnect();
  }, [ref, observer]);
  return [ref, currentId];
}