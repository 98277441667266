import React from 'react';
import { RareEvoWrapper, VideoWrapper, FirstSection } from './style';
import { Carousel } from '../carousel/carousel';
import { LiveStreamReplayVideo } from './livestream-replay-video';
import { SSIFutureFiresideVideo } from './ssi-future-fireside-video';
import { RecapVideo } from './recap-video';

export default function RareEvoVideos() {
  return (
    <RareEvoWrapper>
      {/*  Background */}
      <VideoWrapper>
        <video autoPlay loop muted playsInline>
          <source src="/videos/rare-evo-bg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </VideoWrapper>

      {/*  Content */}
      <FirstSection>
        <Carousel
          slides={[<LiveStreamReplayVideo />, <SSIFutureFiresideVideo />, <RecapVideo />]}
        />
      </FirstSection>
    </RareEvoWrapper>
  );
}
