import { styled } from '@linaria/react';

export const RareEvoWrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  height: 630px;
  overflow: hidden;
  z-index: 1; // above hero image
  background: rgba(0, 0, 0, 0.98);
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;

  video {
    width: 100%;
    max-height: 630px;
    min-height: 630px;
    object-fit: cover;
  }
`;

export const FirstSection = styled.section`
  height: 100%;

  @media screen and (min-width: 700px) {
  }
`;

export const LiveStreamReplayVideoWrapper = styled.div`
  border: 1px solid white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 4em 2em;

  background-image: url('/images/rare-evo-video-bg1.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(5, 5, 5, 0.8);
    z-index: 1;
    //backdrop-filter: brightness(0.3);
  }

  /* Ensure the content is above the overlay */
  > * {
    position: relative;
    z-index: 2;
  }
`;

export const VideoTitleLogo = styled.img`
  max-width: 150px;

  @media screen and (min-width: 600px) {
    max-width: 300px;
  }

  @media screen and (min-width: 980px) {
    max-width: 400px;
  }
`;

export const VideoTitle = styled.h1`
  font-weight: bold;
  font-size: 33px;
  line-height: 40px;
  color: white;
  text-align: center;

  @media screen and (min-width: 980px) {
    font-size: 50px;
    line-height: 58px;
  }
`;

export const LiveStreamReplayVideoYoutubeLogo = styled.img`
  max-width: 200px;
`;

export const YoutubeButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const SSIFutureFiresideVideoWrapper = styled.div`
  border: 1px solid white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 4em 2em;

  background-image: url('/images/atala-sand-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: 700px) {
  }
`;

export const RecapVideoWrapper = styled.div`
  border: 1px solid white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 4em 2em;

  background-image: url('/images/recap-video-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #101010;
`;
