import { JoinCommunity } from '../info-card/join-community';
import React, { Ref } from 'react';
import { CommonSectionProps } from '../../helpers/types';
import { styled } from '@linaria/react';

function JoinCommunitySection({ id }: CommonSectionProps, ref: Ref<HTMLDivElement>) {
  return (
    <JoinCommunityWrapper ref={ref} id={id}>
      <JoinCommunity />
    </JoinCommunityWrapper>
  );
}

export const JoinCommunityWrapper = styled.div`
  padding: 4rem 10%;
  //position: ;
`;

export default React.forwardRef(JoinCommunitySection);
