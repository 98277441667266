import React, { ReactNode, Ref } from 'react';
import {
  SandboxContentWrapper,
  SandboxWrapper,
  ActionButtonWrapper,
  BulletPointsWrapper,
  SandboxContentBody,
} from './style';
import { Container } from '../../helpers/styles';

import { CommonSectionProps } from '../../helpers/types';
import Button from '../button/button';
import data from './data.json';
import { WhyAtalaPrismGrid } from '../why-atala-prism/style';
import PresentCollectionItem from '../present-collection-item/present-collection-item';

function Sandbox({ id }: CommonSectionProps, ref: Ref<HTMLDivElement>) {
  return (
    <SandboxWrapper ref={ref} id={id}>
      <Container>
        <SandboxContentWrapper>
          <h5 className="pre-title">Speed up testing</h5>
          <h1 className="title">Prism Sandbox</h1>
          <SandboxContentBody>
            <div className="left">
              <p>
                The Prism sandbox is a developer's tool that accelerates application development
                with Prism Agents and Wallet SDKs. It provides powerful features to enhance
                workflows and speed up testing and self-sovereign identity (SSI) functionality in
                your project.
              </p>
              <ActionButtonWrapper>
                <Button
                  as="a"
                  href="https://sandbox.atalaprism.io"
                  target="_blank"
                  variant="primary"
                >
                  {'Try Prism Sandbox now >'}
                </Button>
              </ActionButtonWrapper>
            </div>
            <div className="right">
              <WhyAtalaPrismGrid>
                {data.map((props, index) => (
                  <PresentCollectionItem key={index} {...props} variant="primary" />
                ))}
              </WhyAtalaPrismGrid>
            </div>
          </SandboxContentBody>
        </SandboxContentWrapper>
      </Container>
    </SandboxWrapper>
  );
}

export default React.forwardRef(Sandbox);
