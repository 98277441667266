import React, { Ref, useEffect } from 'react';
import { ReachOutIntroContent, ReactOutWrapper } from './style';
import { Container } from '../../helpers/styles';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CommonSectionProps } from '../../helpers/types';

function initForm() {
  //@ts-ignore
  if (!window || !window.hbspt) return;

  //@ts-ignore
  window.hbspt.forms.create({
    region: 'na1',
    portalId: '8848114',
    formId: 'aa317d71-3272-44a8-94dd-e104a8e581d4',
    target: '#hubspotForm',
  });
}

function ReachOut({ id }: CommonSectionProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();

  useEffect(() => {
    // TODO: ugly but couldn't find better way in a rush! Fix it later!
    // TODO: find out how "hbspt" is initialized and check if there is an event we could listen to i norder to know when is it ready
    setTimeout(() => {
      initForm();
    }, 2000);
  }, []);

  return (
    <ReactOutWrapper id={id} ref={ref}>
      <Container>
        <ReachOutIntroContent>
          <div dangerouslySetInnerHTML={{ __html: t('landing.reach-out.html') }} />
          <div id="hubspotForm" />
        </ReachOutIntroContent>
        {/*<img src="/images/atala-background-outline.png" alt="Atala background outlined" width={900.23}/>*/}
      </Container>
    </ReactOutWrapper>
  );
}

export default React.forwardRef(ReachOut);
