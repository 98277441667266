import {
  VideoTitle,
  LiveStreamReplayVideoWrapper,
  LiveStreamReplayVideoYoutubeLogo,
  VideoTitleLogo,
  YoutubeButton,
} from './style';
import React from 'react';
import logo from './rare-evo-logo.png';
import youtubeLogo from './youtube-icon.png';

export function LiveStreamReplayVideo() {
  return (
    <LiveStreamReplayVideoWrapper>
      <VideoTitleLogo src={logo} />
      <VideoTitle>
        RareEVO 2024 <br /> Livestream Replay
      </VideoTitle>
      <YoutubeButton
        onClick={() =>
          window.open(
            'https://youtu.be/VGmZARw1Db4?si=JpmCP5qbZjRoSYEv',
            '_blank',
            'noopener,noreferrer',
          )
        }
      >
        <LiveStreamReplayVideoYoutubeLogo src={youtubeLogo} />
      </YoutubeButton>
    </LiveStreamReplayVideoWrapper>
  );
}
