import { styled } from '@linaria/react';
import React from 'react';
import { UseEmblaCarouselType } from 'embla-carousel-react';
import ArrowLeft from '../../images/arrow-left.svg';
import ArrowRight from '../../images/arrow-right.svg';

export function Controls({ api }: { api: UseEmblaCarouselType[1] }) {
  return (
    <>
      <LeftNav>
        <IconButton
          onClick={() => {
            api?.scrollPrev();
          }}
        >
          <ArrowLeft />
        </IconButton>
      </LeftNav>
      <RightNav>
        <IconButton
          onClick={() => {
            api?.scrollNext();
          }}
        >
          <ArrowRight />
        </IconButton>
      </RightNav>
    </>
  );
}

const LeftNav = styled.div`
  display: none; /* Hide on mobile and small tablets */

  @media screen and (min-width: 800px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const RightNav = styled.div`
  display: none;

  @media screen and (min-width: 800px) {
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

// Create a styled button component
const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;

  &:hover {
  }

  svg {
  }
`;
