import {styled} from "@linaria/react";
import colors from "../../helpers/colors";

export const SectionTrackingHighlight = styled.div`
  margin: 0;
  padding: 0;
  color: ${colors.primary};
`

export const Section = styled.div`
  
`