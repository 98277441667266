import React from "react";
import {Section} from "./style";
import {SectionTrackingComponentTypes} from "./types";
import useSectionTracking from "./use-section-tracking";

export default function SectionTracking({as: As, id: sectionId}: SectionTrackingComponentTypes) {
  const [ref] = useSectionTracking(sectionId);
  return (
      <Section ref={ref} as={As} id={sectionId ?? ""}/>
  );
}