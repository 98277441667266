import { styled } from '@linaria/react';
import colors from '../../helpers/colors';
import getSize from '../../helpers/get-size';
import { Container } from '../../helpers/styles';
import { FONT_18_SIZE } from '../../helpers/constants';

const HERO_MAX_HEIGHT = getSize(841);
export const HeroWrapper = styled.header`
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  z-index: 0;
  height: clamp(${HERO_MAX_HEIGHT * 0.6}em, 90vw, ${HERO_MAX_HEIGHT}em);
  ${Container} {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const HeroImageOverflow = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const HeroMainImageWrapper = styled.div`
  position: absolute;
  top: -300px;
  right: 0;
  opacity: 0;
  transition: opacity ease-in-out 0.5s;

  @media (min-width: 900px) {
    display: block;
    opacity: 1;
  }
`;

export const HeroBackgroundImage = styled.div`
  margin: 0;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    z-index: 0;
  }
`;

const MAX_TITLE_SIZE = getSize(74);
export const HeroContentWrapper = styled.div`
  margin: 0;
  max-width: ${getSize(540)}em;
  h1 {
    color: ${colors.darkblue900};
    font-weight: 700;
    margin-bottom: ${getSize(28)}rem;
    font-size: clamp(${MAX_TITLE_SIZE * 0.6}em, 10vw, ${MAX_TITLE_SIZE}em);
    line-height: ${getSize(18)}em;
  }
  p {
    ${FONT_18_SIZE};
    margin: ${getSize(28)}rem 0;
    max-width: ${getSize(458)}em;
    color: ${colors.darkblue100};
  }
`;
