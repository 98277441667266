import {
  VideoTitle,
  LiveStreamReplayVideoYoutubeLogo,
  RecapVideoWrapper,
  VideoTitleLogo,
  YoutubeButton,
} from './style';
import React from 'react';
import atalaLogo from './atala-prism-logo-2024.png';
import blogButtonImg from './blog-button.png';

export function RecapVideo() {
  return (
    <RecapVideoWrapper>
      <VideoTitleLogo src={atalaLogo} />
      <VideoTitle>RareEVO 2024 Recap</VideoTitle>
      <YoutubeButton
        onClick={() =>
          window.open(
            'https://atala.mymidnight.blog/rareevo-2024-wrap-up/',
            '_blank',
            'noopener,noreferrer',
          )
        }
      >
        <LiveStreamReplayVideoYoutubeLogo src={blogButtonImg} />
      </YoutubeButton>
    </RecapVideoWrapper>
  );
}
