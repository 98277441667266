import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';

import {
  BurgerWrapper,
  ContactWrapper,
  Drawer,
  LogoWrapper,
  MenuItemWrapper,
  MenuWrapper,
  NavbarsWrappers,
  NavbarWrapperDesktop,
  NavbarWrapperMobile,
} from './style';

import { Container } from '../../helpers/styles';
import { NAVBAR_ITEMS } from '../../helpers/constants';
import { NavbarItem } from '../../helpers/types';
import Button from '../button/button';
import { SectionTrackingContext } from '../section-tracking/context';
import { DiscordIcon } from './discord-icon';

function MenuItem({ textKey, opensInNewTab, link, isExternal, currentSectionId }: NavbarItem) {
  const { t } = useTranslation();
  const content = t(textKey);
  const Component = useMemo(() => {
    return isExternal ? 'a' : Link;
  }, [isExternal]);

  const ComponentProps = useMemo(() => {
    return isExternal ? { href: link } : { to: link };
  }, [isExternal, link]);
  return (
    <MenuItemWrapper sectionVisible={`#${currentSectionId}` === link} opensInNewTab={opensInNewTab}>
      <Component to={link} {...ComponentProps} target={opensInNewTab ? '_blank' : ''}>
        {content}
        {opensInNewTab && <img src="/images/corner-top-right.svg" />}
      </Component>
    </MenuItemWrapper>
  );
}

function Navbar() {
  const { t } = useTranslation();
  const ref = useRef<HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { currentId } = useContext(SectionTrackingContext);
  const [top, setTop] = useState<number>(50);
  const toggleMenuOpen = useCallback(() => {
    setMenuOpen((v) => !v);
  }, []);

  const handleSetTop = useCallback(() => {
    setTop(ref.current?.offsetTop ?? 50);
  }, [ref]);
  const handleBannerClose = useCallback(() => {
    handleSetTop();
  }, [handleSetTop]);

  useEffect(() => {
    if (ref.current) {
      handleSetTop();
    }
  }, [handleSetTop, ref]);

  return (
    <NavbarsWrappers>
      <NavbarWrapperDesktop>
        <Container>
          <LogoWrapper>
            <Link to="#hero">
              <img src="/images/atala-prism-logo-2024.png" alt={t('atalaLogo')} width={88.58} />
            </Link>
          </LogoWrapper>
          <MenuWrapper>
            {NAVBAR_ITEMS.map((item, index) => (
              <MenuItem currentSectionId={currentId} key={index} {...item} />
            ))}
          </MenuWrapper>
          <ContactWrapper>
            <Button as={Link} to="#contact" variant="secondary_new">
              {t('navbar.contact') as ReactNode}
            </Button>
            {/*<Button as={Link} to="#start-journey" variant="primary">*/}
            {/*  {t("navbar.identus") as ReactNode}*/}
            {/*</Button>*/}
            <a href="https://discord.gg/atala" target={'_blank'}>
              <DiscordIcon />
            </a>
          </ContactWrapper>
        </Container>
      </NavbarWrapperDesktop>
      <NavbarWrapperMobile ref={ref}>
        <Container>
          <LogoWrapper>
            <Link to="/">
              <img src="/images/atala-prism-logo-2024.png" alt={t('atalaLogo')} width={88.58} />
            </Link>
          </LogoWrapper>
          <BurgerWrapper onClick={toggleMenuOpen} times={menuOpen}>
            <span />
            <span />
            <span />
          </BurgerWrapper>
        </Container>
        <Drawer open={menuOpen}>
          <Container>
            <MenuWrapper>
              {NAVBAR_ITEMS.map((item, index) => (
                <MenuItem currentSectionId={currentId} key={index} {...item} />
              ))}
            </MenuWrapper>
            <ContactWrapper>
              <Button as={Link} to="#contact" variant="secondary_new">
                {t('navbar.contact') as ReactNode}
              </Button>
              <a href="https://discord.gg/atala" target={'_blank'}>
                <DiscordIcon />
              </a>
            </ContactWrapper>
          </Container>
        </Drawer>
      </NavbarWrapperMobile>
    </NavbarsWrappers>
  );
}

export default Navbar;
