import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import colors from '../../helpers/colors';
import getSize from '../../helpers/get-size';
import { lighten } from 'polished';
import { ButtonStyleTypes } from './types';

export const ButtonCSS = css`
  margin: 0;
  padding: 0;
`;
export const ButtonWrapper = styled.div<ButtonStyleTypes>`
  margin: 0;
  padding: 0.5em ${getSize(23.5)}em;
  font-size: ${getSize(14)}em;
  border-radius: 10em;
  display: inline-block;
  text-decoration: none;
  font-weight: 600;
  transition: background-color ease-in-out 0.3s;
  background-color: ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return colors.primary;

      case 'secondary':
        return colors.green900;

      case 'secondary_new':
        return colors.secondary_new;

      case 'tertiary':
        return colors.lightBlue100;

      case 'quaternary':
        return colors.darkGreen;

      case 'quinary':
        return colors.blue200;

      case 'senary':
        return colors.darkblue100;

      case 'hyperledger':
        return colors.hyperledger;

      default:
        return colors.primary;
    }
  }};
  color: ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return colors.white;

      case 'secondary':
        return colors.darkblue900;

      case 'tertiary':
        return colors.black;

      case 'quinary':
        return colors.darkblue900;

      default:
        return colors.white;
    }
  }};
  &:hover {
    color: ${({ variant }) => {
      switch (variant) {
        case 'primary':
          return colors.white;

        case 'secondary':
          return colors.darkblue900;

        case 'secondary_new':
          return colors.white;

        case 'tertiary':
          return colors.black;

        case 'quinary':
          return colors.darkblue900;

        default:
          return colors.white;
      }
    }};

    background-color: ${({ variant }) => {
      switch (variant) {
        case 'primary':
          return lighten(0.1, colors.primary);

        case 'secondary':
          return lighten(0.1, colors.green900);

        case 'secondary_new':
          return lighten(0.1, colors.secondary_new);

        case 'tertiary':
          return lighten(0.1, colors.lightBlue100);

        case 'quaternary':
          return lighten(0.1, colors.darkGreen);

        case 'quinary':
          return lighten(0.1, colors.blue200);

        case 'senary':
          return lighten(0.1, colors.darkblue100);

        case 'hyperledger':
          return lighten(0.05, colors.hyperledger);

        default:
          return lighten(0.1, colors.primary);
      }
    }};
  }
`;
