import React from 'react';
import Button from '../button/button';
import { InfoCard } from './info-card';

export function JoinCommunity() {
  return (
    <InfoCard>
      <h1>Join the conversation!</h1>
      <p>
        Get all the latest news and updates by joining our Discord channel.{' '}
        <span className="hidden-on-xs">
          <br />
          Plus, you can provide your input and guide us on our product development!
        </span>
      </p>
      <Button as="a" href="https://discord.gg/atala" target="_blank" variant="tertiary">
        {'Join the community >'}
      </Button>
    </InfoCard>
  );
}
