import { styled } from '@linaria/react';

export const Wrapper = styled.div`
  overflow: hidden;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 80%;
  height: 100%;

  @media screen and (min-width: 580px) {
    --slide-size: 70%;
    --slide-spacing: 2rem;
  }
`;

export const Container = styled.div`
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
  height: 100%;
  padding: 4rem 0rem;
`;

export const Slide = styled.div`
  flex: 0 0 var(--slide-size);
  max-width: 890px;
  min-width: 0;
  transform: translate3d(0, 0, 0);
  padding-left: var(--slide-spacing);
  height: 100%;
`;
