import * as React from "react"
import { SVGProps } from "react"

export default function SlashIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={10}
            height={13}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.143 0H10L2.857 13H0L7.143 0Z"
                fill="#F26660"
            />
        </svg>
    )
}

